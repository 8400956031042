<form #form="ngForm" (ngSubmit)="onSubmit(form.value)" action="/search">
    <div>
        <div class="form-group input-group">
            <input type="text" [(ngModel)]="query" name="query" class="form-control" attr.aria-label="{{ searchPlaceholder }}"
                   [placeholder]="searchPlaceholder">
            <div *ngIf="showScopeSelector === true" class="input-group-prepend">
                <button class="scope-button btn btn-secondary text-truncate" [ngbTooltip]="(selectedScope | async)?.name" type="button" (click)="openScopeModal()">{{(selectedScope | async)?.name || ('search.form.scope.all' | translate)}} <i class="fas fa-chevron-down fa-sm"></i></button>
                </div>
            <span class="input-group-append">
            <button type="submit" class="search-button btn btn-{{brandColor}}"><i class="fas fa-search"></i></button>
        </span>
        </div>
    </div>
</form>
